@defaultColor: #ffffff;
@titleColor: #727787;
@bgColor: #232837;
@boxColor: #2d3347;
@danger: #ee5f6b;
@success: #5cb45a;

@text12: 12px;
@text14: 14px;
@text16: 16px;
@text20: 20px;
@text24: 24px;
@text28: 28px;
@text32: 32px;

.text-12 {
  font-size: @text12;
}
.text-14 {
  font-size: @text14;
}
.text-16 {
  font-size: @text16;
}
.text-20 {
  font-size: @text20;
}
.text-28 {
  font-size: @text28;
}
.text-32 {
  font-size: @text32;
}

.font-1 {
  font-weight: 1;
}
.font-100 {
  font-weight: 100;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-800 {
  font-weight: 800;
}
.color-white {
  color: @defaultColor;
}
.color1 {
  color: @titleColor;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-column {
  flex-direction: column;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: end;
}

.justify-start {
  justify-content: start;
}
.align-center {
  align-items: center;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.box-border {
  box-sizing: border-box;
}
.loop(@npx) when (@npx <= 30) {
  // margin
  .mr-@{npx} {
    margin-right: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  .ml-@{npx} {
    margin-left: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  .mb-@{npx} {
    margin-bottom: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  .mt-@{npx} {
    margin-top: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  //.mx-@{npx} { margin: 1px * @npx 0; } //避免我们的class被覆盖，使用!important增加权重
  //.my-@{npx} { margin: 0 1px * @npx; } //避免我们的class被覆盖，使用!important增加权重
  // padding
  .pr-@{npx} {
    padding-right: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  .pl-@{npx} {
    padding-left: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  .pb-@{npx} {
    padding-bottom: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  .pt-@{npx} {
    padding-top: 1px * @npx !important;
  } //避免我们的class被覆盖，使用!important增加权重
  //.px-@{npx} { padding: 1px * @npx 0; } //避免我们的class被覆盖，使用!important增加权重
  //.py-@{npx} { padding: 0 1px * @npx; } //避免我们的class被覆盖，使用!important增加权重
  .loop((@npx+1));
}
.loop(2);

.container {
  background: @bgColor;
  width: 100%;
  min-height: 100vh;
}
.box {
  background: @boxColor;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: inset 0px 1px 2px 0px rgba(255, 255, 255, 0.1);
}

