@import "./reset.less";

.Mui-selected {
  color: white !important;
}
.MuiTabs-indicator {
  width: 29px;
  height: 3px;
  background: linear-gradient(153deg, #d7b0fd 0%, #74b9ff 100%);
  box-shadow: inset 0px 3px 4px 0px rgba(255, 255, 255, 0.7);
  border-radius: 12px 12px 12px 12px;
}
.MuiTabs-root {
  color: #727787 !important;
  .MuiTab-textColorPrimary {
    color: #727787;
  }
}
.Mui-disabled {
  color: #ffffff !important;
}
.mySwiper {
  height: 20px !important;
  .swiper-slide {
    text-align: center;
    height: 20px !important;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.MuiLinearProgress-root {
  height: 12px !important;
}
.swiper-pagination-bullet{
  background:#FFFFFF!important;
  opacity: 1!important;
}
.my-bullet-active{
  background: #ff6600!important;
  opacity: 1!important;
}

